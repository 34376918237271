.event-tiles {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1vw;

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        gap: 4vw;
    }
}
