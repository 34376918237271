#contact {
    display: flex;
    gap: 3vw;

    @media screen and (max-width: 600px) {
        flex-direction: column;
    }

    h2 {
        flex: 1 1 33.33%;
    }

    .contact-form {
        flex: 1 1 66.67%;
    }
}
