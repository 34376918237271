@use '../core/variables';

header {
    padding: 3vw 4vw;

    @media screen and (max-width: 800px) {
        padding-block: 6vw;
    }

    .desktop-display {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .mobile-display {
        width: 100%;
        min-height: 48px;
    }

    .fixable-wrapper {
        align-items: center;
        display: flex;
        z-index: 2;

        &.fixed {
            position: fixed;
            width: calc(100% - 8vw);
        }
    }
}

.hamburger-button {
    z-index: 2;
    margin-left: auto;
    background: rgba(variables.$color-white, 1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: rgba(variables.$color-dark, 1);
    }
}

.mobile-display.fixed {
    .hamburger-button {
        position: fixed;
        margin-left: auto;
    }
}

.overlay {
    position: fixed;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(variables.$color-black, 0.8);
}
