#books {
    display: flex;
    align-items: center;

    ul {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1vw;

        @media screen and (max-width: 600px) {
            grid-template-columns: 1fr;
            gap: 4vw;
        }

        li {
            width: 100%;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            a {
                width: 100%;
                line-height: 0.5;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                max-width: 100%;
                height: auto;

                @media screen and (max-width: 600px) {
                    max-width: 140%;
                }
            }
        }
    }
}
