footer {
    width: 100%;
    padding: 4vw;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    text-transform: uppercase;

    @media screen and (max-width: 600px) {
        padding-block: 2rem;
    }
}
