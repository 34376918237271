.pdf-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.pdf-controls {
    width: min(100%, 800px);
    text-align: center;

    p {
        padding-block-start: 1rem;
        font-family: 'Lato', sans-serif;
        font-weight: 300;
        text-transform: uppercase;

        strong {
            display: inline-block;
            padding-inline: 0.2rem;
            font-weight: 600;
        }
    }

    .pdf-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        @media screen and (max-width: 800px) {
            gap: 0.6rem;
        }

        @media screen and (max-width: 270px) {
            flex-direction: column;
        }

        button {
            width: 100%;
            padding-block: 1.4rem;
            font-size: 0.9rem;

            @media screen and (max-width: 800px) {
                padding-block: 1rem;
                font-size: 0.7rem;
            }
        }
    }
}
