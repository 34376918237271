@use '../core/variables';

nav {
    display: flex;
    gap: 22px;

    a {
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        border-top: 0.2rem solid transparent;
        border-bottom: 0.2rem solid transparent;
    }
}

.desktop-display {
    nav {
        margin-left: auto;
        padding-inline: 2vw;
        text-align: right;
        justify-content: flex-end;

        a {
            &:hover {
                box-shadow: inset 0 -0.5rem 0 0 rgba(variables.$color-white, 1);
                border-bottom: 0.2rem solid rgba(variables.$color-white, 1);
            }

            &.active {
                color: rgba(variables.$color-dark, 1);
            }
        }
    }
}

.mobile-display {
    nav {
        flex-direction: column;
        align-items: center;

        a {
            width: fit-content;
            color: rgba(variables.$color-dark, 1);

            &:hover {
                box-shadow: inset 0 -0.5rem 0 0 rgba(variables.$color-black, 1);
                border-bottom: 0.2rem solid rgba(variables.$color-black, 1);
            }

            &.active {
                color: rgba(variables.$color-white, 1);
            }
        }
    }
}
