.event-tile {
    padding: 4vw;
    background-color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    @media screen and (max-width: 600px) {
        padding: 6vw;
    }

    h4 {
        padding-block: 2rem 1rem;
    }

    .organiser {
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        font-size: 0.8rem;
        text-transform: uppercase;
    }

    .date {
        font-family: 'Lato', sans-serif;
        font-weight: 300;
        font-size: 0.8rem;
        text-transform: uppercase;
    }

    .event-description {
        width: 100%;
        padding-bottom: 2rem;
        font-size: 0.8rem;
    }

    .linked-icon-list {
        font-size: 1.4rem;
        line-height: 1;
        display: flex;
        gap: 0.8rem;

        a:hover {
            color: var(--icon-hover-color) !important;   
        }
    }
}
