@use '../core/variables';

$element-height: 70px;
$icon-height: 14px;
$element-padding: calc(($element-height - $icon-height) / 2);

form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.8rem;

    @media screen and (max-width: 600px) {
        gap: 1rem;
    }

    button {
        width: 100%;
        height: $element-height;
    }
}

%form-element {
    position: relative;
    width: 100%;
    padding-block: $element-padding;
    padding-inline: calc(4.5 * $icon-height) $element-padding;
    border: 0;
    border-radius: 4px;
}

.contact-input {
    width: 100%;
    position: relative;

    input {
        @extend %form-element;
        height: $element-height;
        overflow: visible;
    }

    textarea {
        @extend %form-element;
        line-height: 1.6;
        resize: none;
        overflow: auto;
    }
}

.contact-icon {
    position: absolute;
    top: $element-padding;
    left: calc(2 * $icon-height);
    text-align: center;
    color: rgba(variables.$color-light, 0.7);
    font-size: $icon-height;
    line-height: 1;
}

textarea + .contact-icon {
    line-height: 2;
}

input:focus + .contact-icon,
textarea:focus + .contact-icon {
    color: rgba(variables.$color-dark, 1);
}
