@use '../core/variables';

.language-toggle {
    button {
        background: rgba(variables.$color-white, 1);
        width: 48px;
        height: 48px;
        font-weight: 900;
        font-size: 0.8rem;
        text-transform: uppercase;
        border-radius: 50%;

        &:hover {
            color: rgba(variables.$color-white, 1);
            background: rgba(variables.$color-dark, 1);
        }
    }
}

.desktop-display {
    .language-toggle {
        margin-inline-end: 2vw;
    }
}

.mobile-display {
    .language-toggle {
        position: fixed;
        bottom: 3vw;
        right: 4vw;

        @media screen and (max-width: 800px) {
            bottom: 6vw;
        }
    }
}
