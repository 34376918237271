.about-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: min(8rem, 8vw);

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }

    .portrait {
        img {
            max-width: 100%;
            height: auto;
            border-radius: 4px;
        }
    }

    p {
        padding-block: 0.6rem;
        text-align: justify;

        &:first-of-type {
            padding-block-start: 0;
        }
    }
}
