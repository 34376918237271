@use '../core/variables';

.logo {
    color: rgba(variables.$color-text, 1);
    font-family: 'Libre Baskerville', serif;
    font-style: italic;
    font-size: calc(1.2vw + 1rem);
    text-align: left;
    text-transform: lowercase;
    z-index: 2;

    a {
        color: rgba(variables.$color-dark, 1);
    }
}
