@use '../core/variables';

.socials {
    .linked-icon-list {
        font-size: 1.2rem;
        line-height: 0.8;
        display: flex;
        gap: 0.6rem;
    }
}
