@use 'variables';

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

body {
    max-width: 1200px;
    margin-inline: auto;
    background-color: rgba(variables.$color-light, 1);
    color: rgba(variables.$color-text, 1);
    font-family: 'Lora', serif;
}

section {
    width: 100%;
    padding: 0 4vw;
}

h1,
h2 {
    margin-bottom: 2rem;
    color: rgba(variables.$color-text, 1);
    font-family: 'Lato', sans-serif;
    font-weight: 800;
}

h3 {
    width: fit-content;
    margin-block: 2rem;
    padding-inline: 1.6rem;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-style: italic;
    text-transform: uppercase;
    box-shadow: inset 0 -0.5rem 0 0 rgba(variables.$color-white, 1);
    border-bottom: 0.2rem solid rgba(variables.$color-white, 1);

    &:first-of-type {
        margin-top: 0;
    }
}

h4 {
    font-size: 1.2rem;
    font-family: 'Lora', serif;
    font-weight: 400;
}

p {
    line-height: 1.8;
}

a {
    color: rgba(variables.$color-text, 1);

    &:hover {
        color: rgba(variables.$color-dark, 1);
    }
}

%handles {
    font-family: 'Lato', sans-serif;
    font-size: 100%;
    line-height: 1;
}

input,
textarea {
    @extend %handles;
    font-weight: 300;
}

button {
    @extend %handles;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
}

.btn {
    color: rgba(variables.$color-white, 1);
    background: rgba(variables.$color-dark, 1);
    border-radius: 4px;

    &:hover {
        color: rgba(variables.$color-bg, 1);
        background-color: rgba(variables.$color-black, 1);
    }

    &.disabled,
    &.disabled:hover {
        background: rgba(variables.$color-gray, 1);
        cursor: default;
    }
}

.info {
    font-style: italic;
    text-transform: lowercase;
}
