@use '../core/variables';

.book {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 8vw;

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        gap: 4vw;
    }
}

.book-cover {
    width: 100%;
    line-height: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    overflow: hidden;

    img {
        max-width: 100%;
        height: auto;

        @media screen and (max-width: 600px) {
            max-width: 120%;
        }
    }
}

.book-details {
    width: 100%;
    margin-top: 2rem;
    font-family: 'Lato', sans-serif;
    font-size: 0.9rem;
    text-transform: uppercase;

    @media screen and (max-width: 600px) {
        margin-top: 1rem;
    }

    th,
    td {
        padding-block: 0.4rem;
        width: 50%;
        vertical-align: top;
    }

    th {
        padding-right: 0.3rem;
        text-align: right;
        font-weight: 300;
    }

    td {
        padding-left: 0.3rem;
        text-align: left;
        font-weight: 500;
    }
}

.quote {
    position: relative;

    p {
        text-align: justify;
        white-space: pre-wrap;
    }

    // Firefox doesn't support conjunction of "text-align: justify"
    // and "white-space: pre-wrap"; thus, the ugly code below prioritises
    // text-align over white-space and adds padding for readability
    @-moz-document url-prefix() {
        p {
            white-space: normal;
            padding-bottom: 0.6rem;
        }
    }
}
